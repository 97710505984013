@use "mixins" as *
.casinoFavoritesModal
  height: calc(100% - 60px)
  top: 0
  z-index: 6

  & :global
    .modal
      width: 100%
      height: calc(100% - var(--headerHeight))
      overflow: hidden
      background: var(--modal_bg)
      display: flex
      flex-direction: column
    .no-game
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%)
      white-space: nowrap
      margin: 0

.favoritesModalHeader
  @include flex
  height: 40px
  background: linear-gradient(180deg, rgba(#fff, 0.95) 0%, rgba(#dfe6f3, 0.95) 100%)
  border-radius: 6px 6px 0 0
  overflow: hidden
  color: var(--txt_color)
  text-transform: uppercase
  @include bold

.favoritesBarIcon
  composes: favoritesBarIcon from "components/pages/casino/favorites/casino-favorites-panel.module.sass"
  margin-right: 10px
  margin-bottom: 0

.openFavoritesBtn
  composes: openFavoritesBtn from "components/pages/casino/favorites/casino-favorites-panel.module.sass"
  &:after
    transform: none

.casinoFavoritesModalGames
  height: calc(100% - 40px)
  overflow: auto
  display: grid
  align-items: baseline
  padding: 10px 6px
  gap: 10px
  grid-template-columns: repeat(9, 1fr)

  :global(.casino-game-item)
    margin: 0
    width: 100%
    max-width: none
    > div
      max-height: none

@media screen and (max-width: 1800px)
  .casinoFavoritesModalGames
    grid-template-columns: repeat(8, 1fr)

@media screen and (max-width: 1460px)
  .casinoFavoritesModalGames
    grid-template-columns: repeat(7, 1fr)

@media screen and (max-width: 1280px)
  .casinoFavoritesModalGames
    grid-template-columns: repeat(6, 1fr)

@media screen and (max-width: 1000px)
  .casinoFavoritesModalGames
    grid-template-columns: repeat(5, 1fr)

@media screen and (max-width: 800px) and (orientation: landscape)
  .casinoFavoritesModalGames
    grid-template-columns: repeat(4, 1fr)

@media screen and (max-width: 650px) and (orientation: landscape)
  .casinoFavoritesModalGames
    grid-template-columns: repeat(3, 1fr)

@media screen and  (min-width: 801px) and (max-width: 870px)
  .casinoFavoritesModalGames
    --casino_gameItemNameHeight: 24px

@media screen and (max-width: 800px) and (orientation: portrait)
  .casinoFavoritesModalGames
    grid-template-columns: repeat(4, 1fr)

@media screen and  (min-width: 651px) and (max-width: 700px) and (orientation: portrait)
  .casinoFavoritesModalGames
    --casino_gameItemNameHeight: 24px

@media screen and (max-width: 650px) and (orientation: portrait)
  .casinoFavoritesModalGames
    grid-template-columns: repeat(3, 1fr)

@media screen and  (min-width: 481px) and (max-width: 524px) and (orientation: portrait)
  .casinoFavoritesModalGames
    --casino_gameItemNameHeight: 24px
    --casino_gameItemNameFontSize: 11px

@media screen and (max-width: 480px) and (orientation: portrait)
  .casinoFavoritesModalGames
    grid-template-columns: repeat(2, 1fr)

@media screen and (max-width: 370px) and (orientation: portrait)
  .casinoFavoritesModalGames
    --casino_gameItemNameHeight: 24px

@media screen and (max-width: 340px) and (orientation: portrait)
  .casinoFavoritesModalGames
    --casino_gameItemNameFontSize: 11px
