@use "mixins" as *
.dropdownModal
  height: calc(100% - var(--headerHeight) - var(--menuHeight) - 47px)
  & :global
    .modal
      width: 50%
      height: auto
      bottom: 16px
      background: #ccd4e1
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5)
      border-radius: 14px 14px 6px 6px
      padding: 2px 2px 44px
      overflow: inherit
      transition: all 0.3s
      max-width: 220px
      & :global
        .casino-game-item
          margin: 0
          width: 100%
          max-width: none

          > div
            overflow: unset
            border-width: 1px
            &::before
              content: none
            :global(.imageNext)
              border-radius: var(--casino_gameItemRadius)
              overflow: hidden
            > div
              opacity: 1
              display: flex
              flex-direction: inherit
              height: 46px
              width: 100%
              top: 100%
              transform: translate(-50%, 0)

              button
                margin-top: 0
                min-width: auto
                flex: 1
                font-size: 14px
                box-shadow: none

                &:nth-child(2)
                  max-width: 68px
                  margin-left: 6px
                  background: var(--casinoCompact_favoritesTryBtnBg)
                  color: #fff
                  &:active
                    background: var(--casinoCompact_favoritesTryBtnActiveBg)

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 900px)
  .dropdownModal
    z-index: 16
    :global(.modal)
      width: 28%
