@use "mixins" as *
.favoritesBar
  @include flex
  background: linear-gradient(180deg, rgba(#fff, 0.95) 0%, rgba(#dfe6f3, 0.95) 100%)
  border-radius: 6px 6px 0 0
  padding-bottom: 5px
  position: fixed
  width: 100%
  bottom: 60px
  z-index: 3
  overflow: hidden
  color: var(--txt_color)

.favoritesBarIcon
  overflow: hidden
  width: 40px
  min-width: 40px
  height: 45px
  position: relative
  margin-bottom: -3px
  @include flex
  justify-content: center
  &:before
    content: ""
    width: 56px
    height: 56px
    border-radius: 100%
    background: rgba(var(--primaryColor), 0.8)
    box-shadow: inset -4px 0px 4px rgba(0, 0, 0, 0.1)
    position: absolute
    right: 0
    top: -5px
  img
    margin-right: 4px
    position: relative
    z-index: 1

.openFavoritesBtn
  @include flex
  margin-left: auto
  @include bold
  padding: 14px 16px
  height: 100%
  &:after
    content: ""
    margin-left: 6px
    background-color: var(--txt_color)
    mask-image: var(--icon-menu-double-arrow)
    width: 10px
    height: 10px
    display: inline-block
    transform: rotate(180deg)
