@use "mixins" as *
.favoriteItemCompact
  width: 28px
  height: 33px
  border-radius: 4px
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4)
  overflow: hidden
  margin-left: 12px
  margin-top: 1px
  border: 1px solid rgba(#000, 0.75)
  @include flex
  justify-content: center
  position: relative

  :global(.loader)
    background: none
    span
      width: 10px
      height: 10px
      font-size: 10px
      color: rgba(0, 0, 0, 0.75)

  &.selected
    border-color: rgba(#000, 1)
    box-shadow: none
  video
    height: 48px
  :global(.imageNext)
    height: 100%
    width: 100%
    justify-content: center
    position: relative
    img
      height: 48px

    &:after
      content: ""
      position: absolute
      left: 0
      top: 0
      height: 16px
      width: 100%
      background: linear-gradient(to bottom,rgba(255,255,255, 0.3) 0%,rgba(255,255,255, 0.01) 100%)
